@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.title {
  @include font-style-h4;
  margin: 0;
}

.description {
  @include font-style-small;
  margin: 0;
  line-height: $line-height-tiny;
}
